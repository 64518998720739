<template>
  <div>
    <span v-if="index < visibleItems">{{ formattedLabel }}</span>
    <span v-if="isSpacerVisible">&nbsp;</span>
    <span
      v-if="index < arrayLength - 1 && index < (visibleItems - 1)"
      class="mr-1"
    >,</span>
    <span
      v-if="index === visibleItems"
    >
      (+{{ arrayLength - visibleItems }})&nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    visibleItems: {
      type: Number,
      default: 1,
    },
    index: {
      type: Number,
      required: true,
    },
    arrayLength: {
      type: Number,
      required: true,
      default: 1
    },
    label: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  computed: {
    isSpacerVisible() {
      return this.index + 1 === this.visibleItems || this.arrayLength + 1 === this.visibleItems
    },
    formattedLabel() {
      return this.maxLength && this.label.length > this.maxLength ? `${this.label.substring(0, this.maxLength)}...` : this.label
    }
  },
}
</script>
